import { SystemStyleObject } from "@styled-system/css";
import backgroundImage from "../../Assets/background.svg";

export function baseStyles(props: any): SystemStyleObject {
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // height: "100vh",
    background: `url(${backgroundImage})`,
  };
}
