import { useLocation } from "react-router";
import { Container } from "../../components/Container";
import { newsPageStyles } from "../../styles/pages";
import { commonStyles } from "../../styles/components";
import { Font } from "../../components/Font";
import { Image } from "../../components/Image";
import { Loading } from "../../components/Loading";

function News() {
  const { state } = useLocation();
  const { news } = state;


  const {
    baseContainer,
    headingBoxStyle,
    headingTextStyle,
    bodyTextStyle,
    pageContentBox,
  } = commonStyles;
  console.log('test news', news)

  if (!news) {
    return <Loading />;
  }

  return (
    <Container styles={baseContainer}>
      <Container styles={headingBoxStyle}>
        <Font styles={headingTextStyle}>{news.title}</Font>
      </Container>
      <Container styles={pageContentBox}>
        <Container styles={newsPageStyles.newsImageContainer}>
          <Image
            loading="lazy"
            alt="news photo"
            src={news.image}
            styles={newsPageStyles.newsImage}
          />
        </Container>
        <Font styles={bodyTextStyle}>{news.news}</Font>
      </Container>
    </Container>
  );
}

export default News;
