export const getTranslations = (content: any) => {
  if (content) {
    return {
      aboutUsTitle: content.aboutUsTitle??"ABOUT US",
      aboutPageTexts: content.aboutPageTexts ?? "Himalayan Sports Club (HSC) ry is a registered non-profit sports organization based in Helsinki, Finland. The organization was established in February 2021. Currently, Himalayan Sports Club has its own volleyball and football teams. The club organizes regular volleyball and football training sessions in Helsinki metropolitan area.## The story of Himalayan Sports Club goes back to 2012, when a group of friends started to play volleyball together. The team grew very popular amongst the Nepalese community in Helsinki and they were known as Himalayan Spikes. This team participated yearly in various volleyball tournaments and were able to win numerous trophies. As more people were welcomed into the team, we decided to register and become an organization called Himalayan Sports Club. Currently, the club is participating in volleyball and football tournaments held within Finland. Today, Himalayan Sports Club aims to provide several sports platforms aimed especially at the younger generation without forgetting all sports enthusiasts.## The main aspiration of the organization is to create a greater sense of community by engaging in various sports activities and promote a healthy lifestyle among the Nepalese community. In the future, Himalayan Sports Club aims to organize various sports and recreational events regularly.",
      boardTitle: content.boardTitle??"EXECUTIVE BOARD",
      documentsTitle: content.documentsTitle ?? "DOCUMENTS AND REPORTS",
      membersTitle:content.aboutPageMemberTitle??"CLUB MEMBERSHIP",
      membersIntro: content.aboutPageMemberIntro ?? "Care to become a member of Himalayan Sports club? A person with interest/skill in football or volleyball, and has been active in club's sports or non-sports activities for last 6 months is eligible for a club member. Fill-in the following form to become a member.",
      membersText: content.aboutPageMemberText ?? "Club general membership form",
      memberLink:content.aboutPageMemberLink??"himalayansportsclub.com"

    };
  } else {
     return {
      aboutUsTitle:"ABOUT US",
      aboutPageTexts:"Himalayan Sports Club (HSC) ry is a registered non-profit sports organization based in Helsinki, Finland. The organization was established in February 2021. Currently, Himalayan Sports Club has its own volleyball and football teams. The club organizes regular volleyball and football training sessions in Helsinki metropolitan area.## The story of Himalayan Sports Club goes back to 2012, when a group of friends started to play volleyball together. The team grew very popular amongst the Nepalese community in Helsinki and they were known as Himalayan Spikes. This team participated yearly in various volleyball tournaments and were able to win numerous trophies. As more people were welcomed into the team, we decided to register and become an organization called Himalayan Sports Club. Currently, the club is participating in volleyball and football tournaments held within Finland. Today, Himalayan Sports Club aims to provide several sports platforms aimed especially at the younger generation without forgetting all sports enthusiasts.## The main aspiration of the organization is to create a greater sense of community by engaging in various sports activities and promote a healthy lifestyle among the Nepalese community. In the future, Himalayan Sports Club aims to organize various sports and recreational events regularly.",
      boardTitle:"EXECUTIVE BOARD",
       documentsTitle: "DOCUMENTS AND REPORTS",
      membersTitle:"CLUB MEMBERSHIP",
      membersIntro: "Care to become a member of Himalayan Sports club? A person with interest/skill in football or volleyball, and has been active in club's sports or non-sports activities for last 6 months is eligible for a club member. Fill-in the following form to become a member.",
      membersText:"Club general membership form",
      memberLink:"himalayansportsclub.com"
    };
  }
};
