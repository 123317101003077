import React, { createContext, useContext, useState } from "react";
import { T_PageTexts } from "../types.general";
type T_contextProps = {
  children: React.ReactNode;
};
export type T_ContentfulContext = {
  assets: any;
  updateAssets: (data: any) => void;
  news: any;
  updateNews: (data: any) => void;
  notices: any;
  updateNotices: (data: any) => void;
  galary: any;
  updateGalary: (data: any) => void;
  pageTexts: T_PageTexts[];
  updatePageTexts: (data: any) => void;
  sponsors: any;
  updateSponsors: (data: any) => void;
  players: any;
  updatePlayers: (data: any) => void;
  boardMembers: any;
  updateBoardMembers: (data: any) => void;
  documents: any;
  updateDocuments: (data: any) => void;
};

const initialState: T_ContentfulContext = {
  assets: [],
  updateAssets: () => null,
  news: [],
  updateNews: () => null,
  notices: [],
  updateNotices: () => null,
  galary: [],
  updateGalary: () => null,
  pageTexts: [],
  updatePageTexts: () => null,
  sponsors: [],
  updateSponsors: () => null,
  players: [],
  updatePlayers: () => null,
  boardMembers: [],
  updateBoardMembers: () => null,
  documents: [],
  updateDocuments: () => null,
};

const ContentfulContext = createContext<T_ContentfulContext>(initialState);

export const useContentfulContext = () => {
  return useContext(ContentfulContext);
};

export const ContextProvider = (props: T_contextProps) => {
  const [assets, setAssets] = useState<any>();
  const [sponsors, setSponsors] = useState<any>();
  const [news, setNews] = useState<any>();
  const [notices, setNotices] = useState<any>();
  const [galary, setGalary] = useState<any>();
  const [players, setPlayers] = useState<any>();
  const [pageTexts, setPageTexts] = useState<any>();
  const [boardMembers, setBoardMembers] = useState<any>();
  const [documents, setDocuments] = useState<any>();

  function updateAssets(data: any) {
    setAssets((prevState: any) => {
      return { ...prevState, ...data };
    });
  }
  function updateNews(data: any) {
    setNews((prevState: any) => {
      return { ...prevState, ...data };
    });
  }
  function updateNotices(data: any) {
    setNotices((prevState: any) => {
      return { ...prevState, ...data };
    });
  }

  function updateGalary(data: any) {
    setGalary((prevState: any) => {
      return data;
    });
  }
  function updatePlayers(data: any) {
    setPlayers((prevState: any) => {
      return data;
    });
  }

  function updatePageTexts(data: any) {
    setPageTexts((prevState: any) => {
      return data;
    });
  }

  function updateSponsors(data: any) {
    setSponsors((prevState: any) => {
      return data;
    });
  }
  function updateBoardMembers(data: any) {
    setBoardMembers((prevState: any) => {
      return data;
    });
  }
  function updateDocuments(data: any) {
    setDocuments((prevState: any) => {
      return data;
    });
  }

  return (
    <ContentfulContext.Provider
      value={{
        assets,
        updateAssets,
        news,
        updateNews,
        notices,
        updateNotices,
        galary,
        updateGalary,
        pageTexts,
        updatePageTexts,
        sponsors,
        updateSponsors,
        players,
        updatePlayers,
        boardMembers,
        updateBoardMembers,
        documents,
        updateDocuments,
      }}
    >
      {props.children}
    </ContentfulContext.Provider>
  );
};
