import { createClient } from "contentful";

const contentfulConfig = {
  space: process.env.REACT_APP_CONTENTFUL_SPACE as string,
  environment: process.env.REACT_APP_CONTENTFUL_ENV_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN as string,
  host: process.env.REACT_APP_CONTENTFUL_HOST,
};

const client = createClient(contentfulConfig);

export const fetchContentful = async () => {
  try {
    let data: any = {
      assets: [],
      news: [],
      sponsors: [],
      pageTexts: [],
      galary: [],
      players: [],
      documents: [],
      boardMembers: [],
    };

    //assets
    const assets = await client.getAssets();
    if (assets) {
      const filteredAssets= assets.items.filter(asset=>asset.fields.file)
      const refinedAssets = filteredAssets.map((item) => {
        const imageSize = item.fields.file && item.fields.file.details.size
        
        let image
        if (imageSize > 2500000) {
          image= {title: item.fields.title, description: item.fields.description, url:`${item.fields.file.url}?q=50` }
        } else {
          image= {title: item.fields.title, description: item.fields.description, url:item.fields.file.url }
        }
        return image
      });
      data.assets = refinedAssets;
    }

    //Page texts
    const pageTexts = await client.getEntries({ content_type: "pageText" });
    if (pageTexts) {
      const refinedPageTexts = pageTexts.items.map((item: any) => {
        return item.fields && item.fields;
      });

      data.pageTexts = refinedPageTexts;
    }

    //News
    const news = await client.getEntries({ content_type: "news" });
    if (news) {
      const refinedNews = news.items.map((news: any) => {
        const image = news.fields.image.fields.file.url;
        return {
          title: news.fields.title,
          image: image,
          news: news.fields.news,
        };
      });
      data.news = refinedNews;
    }

    //sponsor
    const sponsors = await (
      await client.getEntries({ content_type: "sponsor" })
    ).items;
    if (sponsors) {
      const refinedSponsors = sponsors.map((item: any) => {
        const imageUrl = item.fields.image.fields.file.url;
        return {
          sponsorName: item.fields.sponsorName,
          mainSponsor: item.fields.mainSponsor,
          url: item.fields.url,
          image: imageUrl,
        };
      });
      data.sponsors = refinedSponsors;
    }

    // galary
    const galary = await (
      await client.getEntries({ content_type: "galary" })
    ).items;
    if (galary) {
      const refinedGalary = galary.map((item: any) => {
        const images = item.fields.images.map(
          (item: any) => {
            const imageSize = item.fields.file.details.size;
            const url = imageSize > 2500000 ? `${item.fields.file.url}?q=50` : item.fields.file.url;
            return url
          }
        );
        return {
          title: item.fields.title,
          images: images,
        };
      });
      data.galary = refinedGalary;
    }
    // player
    const player = await (
      await client.getEntries({ content_type: "player" })
    ).items;
    if (player) {
      const refinedPlayer = player.map((item: any) => {
        const imageSize = item.fields.photo.fields.file.details.size
        const imageUrl= imageSize>2500000?`${item.fields.photo.fields.file.url}?q=50`:item.fields.photo.fields.file.url
        return {
          name: item.fields.name,
          sport: item.fields.sport,
          position: item.fields.position,
          photo: imageUrl,
        };
      });
      data.players = refinedPlayer;
    }

    // Board Members
    const boardMembers = await (
      await client.getEntries({ content_type: "boardMember" })
    ).items;
    if (boardMembers) {
      const refinedBoardMembers = boardMembers.map((item: any) => {
        return {
          name: item.fields.name,
          position: item.fields.position,
          rank: item.fields.rank,
          phone: item.fields.phone,
          tenure: item.fields.tenure,
          photo: item.fields.photo.fields.file.url,
        };
      });

      data.boardMembers = refinedBoardMembers.sort((a, b) => a.rank - b.rank);
    }

    // Documents
    const documents = await (
      await client.getEntries({ content_type: "document" })
    ).items;
    if (documents) {
      const refinedDocuments = documents.map((item: any) => {
        return {
          title: item.fields.title,
          file: item.fields.file.fields.file.url,
        };
      });
      data.documents = refinedDocuments;
    }

    return data;
  } catch (error) {
    console.log("Error getting contentful data:", error);
  }
};
