import { SystemStyleObject } from "@styled-system/css";
import { globalStyles } from "../global";

export function boardMembersContainer(props: any): SystemStyleObject {
  return {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "20px 0",
  };
}
export function boardMemberContainer(props: any): SystemStyleObject {
  return {
    margin: "10px",
  };
}
export function boardMemberPhoto(props: any): SystemStyleObject {
  return {
    width: "250px",
    height: "250px",
    borderRadius: "4px 4px 0 0",
  };
}
export function boardMemberInfoContainer(props: any): SystemStyleObject {
  return {
    width: "250px",
    backgroundColor: "#063A5B",
    padding: "10px",
    borderRadius: "0 0 4px 4px",
  };
}
export function boardMemberName(props: any): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    fontWeight: "bold",
    color: "white",
    fontSize: ["18px", "22px"],
    marginBottom: "10px",
  };
}
export function boardMemberInfoText(props: any): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    color: "white",
    fontSize: ["14px", "18px"],
    marginBottom: "5px",
  };
}
export function documentContainer(props: any): SystemStyleObject {
  return {
    width: "100%",
    padding: "20px",
  };
}
