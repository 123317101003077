import React from "react";
import { componentsStyles } from "../styles";
import { Container } from "./Container";
import { useNews } from "../hooks/useTranslations";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Font } from "./Font";
import { useNavigate } from "react-router";

type T_NewsScrollProps = {
  title: string;
};

export const NewsScroll = (props: T_NewsScrollProps) => {
  const navigate = useNavigate();
  const { scrollContainer, scrollText, newsListWrapper, newsWrapper, shortNews, more, title } =
    componentsStyles.newsScrollStyles;
  const news = useNews();

  if (!news || news.length < 1) {
    return null;
  }

  const handleNewsClick = (news: any) => {
    navigate(`/news/${news.id}`, { state: { news: news } });
  };

console.log(news)

  return (
    <Container styles={scrollContainer}>
      <Font styles={title}>{props.title}</Font>
      <Container styles={newsListWrapper}>
        {news?.map((news: any, index: any) => (
          <Container
            key={index}
            styles={newsWrapper}
          >
            <Font styles={scrollText}>{news.title}  </Font>
            <Font styles={shortNews}>{`${news.news.substring(0, 120)}...` }</Font>
            <Font onClick={() => handleNewsClick(news)} styles={more}>Read more ...</Font>
          </Container>
        ))}
      </Container>
    </Container>
  );
};
