import React, { useEffect, useState } from "react";
import { useContentfulContext } from "../../Api/context";
import { Container } from "../../components/Container";
import { Font } from "../../components/Font";
import { Image } from "../../components/Image";
import { Loading } from "../../components/Loading";
import { usePageContent } from "../../hooks/useTranslations";
import { pageStyles } from "../../styles";
import { commonStyles } from "../../styles/components";
import { getTranslations } from "./translations";

function Players() {
  const [sportType, setSportType] = useState("")
  const translations: any = usePageContent({ page: "Landing Page" });
  const content = getTranslations(translations);
  const context = useContentfulContext();
  const { baseContainer, headingBoxStyle, headingTextStyle } = commonStyles;
  const {
    playersBlockContainer,
    playersListContainer,
    playerInfoWrapper,
    playerPhoto,
    playerAndPhotoWrapper,
    playerName,
    playerInfo,
  } = pageStyles.playersStyles;

  const { players } = context;

  const filteredPlayers= players && players.length>0 && players.filter((player:any)=>!sportType?player:player.sport.toLowerCase().includes(sportType) )
  if (!translations) {
    return <Loading />;
  }




  return (
    <Container styles={baseContainer}>
      <Container styles={headingBoxStyle}>
        <Font styles={headingTextStyle}>HSC PLAYERS </Font>
      </Container>
      <Container styles={playersBlockContainer}>
        <Container styles={{ display: "flex" }}>
          <input checked={sportType==="football"} onChange={()=>setSportType("football")} type="radio"/>
          <Font styles={{margin:"0 30px 0 7px"}}>Football</Font>
          <input checked={sportType==="volleyball"} onChange={()=>setSportType("volleyball")} type="radio"/>
          <Font styles={{margin:"0 30px 0 7px"}}>Volleyball</Font>
        </Container>
      <Container styles={playersListContainer}>
        {!filteredPlayers ||filteredPlayers.length < 1 ? (
          <h5>Players will be added soon</h5>
        ) : (
          filteredPlayers.map((player: any, index:any) => (
            <Container key={ index} styles={playerAndPhotoWrapper}>
              <Image loading="lazy" styles={playerPhoto} alt="hsc player" src={player.photo} />
              <Container styles={playerInfoWrapper}>
                <Font styles={playerName}>{player.name}</Font>
                <Font
                  styles={playerInfo}
                >{`${player.sport} || ${player.position} `}</Font>
              </Container>
            </Container>
          ))
        )}
      </Container>

      </Container>
    </Container>
  );
}

export default Players;
