import React from "react";
import { useContentfulContext } from "../../Api/context";
import { Container } from "../../components/Container";
import { pageStyles } from "../../styles";

import { Heropic } from "../../components/Heropic";
import { Loading } from "../../components/Loading";
import { NewsScroll } from "../../components/newsScroll";
import { Sponsors } from "../../components/Sponsors";
import { usePageContent } from "../../hooks/useTranslations";
import { getTranslations } from "./translations";

function LandingPage() {
  const translations: any = usePageContent({ page: "Landing Page" });
  const context = useContentfulContext();
  const sponsors = context.sponsors;

  const textData = getTranslations(translations);

  if (!textData ||!sponsors) {
    return <Loading />;
  }

  return (
    <Container styles={pageStyles.landingpageStyles.container}>
      <Heropic />
      <NewsScroll title={textData.newsUpdateTitle} />
      {sponsors && (
      <Sponsors data= {sponsors} title={textData.ourPartnersTitle} />
      )}
    </Container>
  );
}

export default LandingPage;
