import { FunctionComponent } from "react";
import { css } from "@styled-system/css";
import styled from "styled-components";
import { SystemStyleObject } from "@styled-system/css";

type T_RootElementProps = {
  styles: SystemStyleObject;
  src: string;
  alt: string;
  loading: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};
export const Image: FunctionComponent<T_RootElementProps> = styled.img<T_RootElementProps>`
  ${(props: T_RootElementProps) => css(props.styles)};
`;

// Image.defaultProps = { src: "", alt: "" };
