import { SystemStyleObject } from "@styled-system/css";
import { globalStyles } from "../global";

export function baseContainer(props: any): SystemStyleObject {
  return {
    display: "flex",
    flexDirection: "column",
    width: ["100%", "90%", "80%", "60%", "50%"],
    margin: "15px auto",
    height: "auto",
  };
}
export function headingBoxStyle(props: any): SystemStyleObject {
  return {
    width: "100%",
    backgroundColor: "white",
    boxShadow: "1px 3px 5px black",
    borderRadius: ["none", "2px"],
    padding: " 5px 10px",
    marginBottom: "15px",
  };
}
export function headingTextStyle(props: any): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    fontWeight: "bold",
    color: globalStyles.dark,
    fontSize: ["18px", "22px"],
    lineHeight: "1.5",
  };
}

export function bodyTextStyle(): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    color: globalStyles.dark,
    fontSize: "18px",
    lineHeight: "1.5",
    marginBottom: "10px",
    whiteSpace: "pre-line"
  };
}

export function link(props: any): SystemStyleObject {
  return {
    ...bodyTextStyle(),
    color:"purple",
    cursor:"pointer"
  };
}

export function pageContentBox(props: any): SystemStyleObject {
  return {
    width: "100%",
    backgroundColor: "white",
    boxShadow: "1px 3px 5px black",
    borderRadius: ["none", "4px"],
    padding: "20px",
    marginBottom: "15px",
  };
}
