import React, { useEffect, useState } from "react";
import { useContentfulContext } from "../../Api/context";
import { Container } from "../../components/Container";
import { usePageContent } from "../../hooks/useTranslations";
import { commonStyles } from "../../styles/components";
import { pageStyles } from "../../styles";
import { getTranslations } from "./translations";
import { Font } from "../../components/Font";
import { Image } from "../../components/Image";
import { Loading } from "../../components/Loading";

function Galary() {
  const [showGalary, setShowGalary] = useState(false)
  const translations: any = usePageContent({ page: "Galary" });
  const textContent = getTranslations(translations);
const context = useContentfulContext();
  const {galary}=context
  
  // const galary: any = [];

  const { headingTextStyle } = commonStyles;
  const {
    baseContainer,
    itemContainer,
    galaryTitle,
    headingBoxStyle,
    imagesContainer,
    imagesContainerLazy,
    image,
  } = pageStyles.galaryStyles;

  setTimeout(() => {
    setShowGalary(true)
  }, 1000);

  if (!translations||!showGalary) {
    return <Container styles={imagesContainerLazy}>
      Loading Galary...
    </Container>;
  }


  return (
    <Container styles={baseContainer}>
      <Container styles={headingBoxStyle}>
        <Font styles={headingTextStyle}>{ textContent.galaryTitle}</Font>
      </Container>
      {galary.length < 1 ? (
        <h1
          style={{
            color: "white",
            textAlign: "center",
            height: "700px",
            marginTop: "100px",
          }}
        >
          Galary will be added soon!
        </h1>
      ) : (
        galary.map((item: any) => {
          return (
            <Container key={item.title} styles={itemContainer}>
              <Font styles={galaryTitle}>{item.title}</Font>
              <Container styles={imagesContainer}>
                {item.images.map((item: any) => {
                  return <Image key={item} loading="lazy" styles={image} alt="galary" src={item} />;
                })}
              </Container>
            </Container>
          );
        })
      )}
    </Container>
  );
}

export default Galary;
