import { SystemStyleObject } from "@styled-system/css";
import { globalStyles } from "../global";

export function scrollContainer(props: any): SystemStyleObject {
  return {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: "10px 20px",
    boxShadow: "1px 50px 45px black",
    marginY: "5px",
  };
}
export function newsListWrapper(): SystemStyleObject {
  return {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    backgroundColor: "white",
  };
}
export function newsWrapper(): SystemStyleObject {
  return {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom:"15px"
  };
}
export function title(): SystemStyleObject {
  return {
    color: "#2a2a2a",
    fontWeight: "bold",
    fontSize: "22px",
    marginBottom: "5px",
  };
}

export function scrollText(props: any): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    color: globalStyles.primaryColor,
    fontSize: "14px",
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
}

export function shortNews(): SystemStyleObject {
  return {
    color: "#2a2a2a",
    fontSize: "14px",
    width: "100%",
    overflow:"clip"
  };
}

export function more(): SystemStyleObject {
  return {
    color: "blue",
    fontSize: "12px",
    marginTop: "7px",
    cursor:"pointer",
  };
}