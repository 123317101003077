import { SystemStyleObject } from "@styled-system/css";

export function container(props: any): SystemStyleObject {
  return {
    width: ["100%","900px"],
    margin:"0 auto"
  };
}

export function heroContainer(props: any): SystemStyleObject {
  return {
    backgroundColor: "RGBA(123,143,190,0.38)",
    width: "100%",
    margin: "0 auto",
    minHeight:"400px"
  };
}
export function heroContainerEmpty(props: any): SystemStyleObject {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems:"center",
    backgroundColor: "RGBA(123,143,190,0.38)",
    width: ["100%", "50%"],
    height:["300px", "400px"],
    margin: "0 auto",
  };
}

export function heroPic(props: any): SystemStyleObject {
  return {
    // objectFit: "cover",
    // maxWidth: ["100%", "50%"],
    // maxWidth: ["100%", "50%", "40%"],
    // height: ["300px", "400px", "450px", "550px"],
    // maxWidth: ["100%", "50%"],
    // maxHeight: "550px",
  };
}

export function mainSponsorsContainer(): SystemStyleObject {
  return {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
  };
}

export function sponsorsHeading(): SystemStyleObject {
  return {
    color: "#2a2a2a",
    margin: "15px 0 30px 0",
    fontSize: "40px",
    fontWeight: "bold",
  };
}

export function sponsorsContainer(props: any): SystemStyleObject {
  return {
    width: ["100%", "70%", "50%"],
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "30px",
  };
}
export function mainSponsorImage(): SystemStyleObject {
  return {
    width: "200px",
    height: "200px",
    objectFit: "contain",
    boxSizing: "border-box",
  };
}
export function generalSponsorsImage(): SystemStyleObject {
  return {
    ...mainSponsorImage(),
    width: "100px",
    height: "100px",
  };
}
