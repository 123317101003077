import React from "react";
import { useLocation } from "react-router-dom";

import { Container } from "../../components/Container";
import { Font } from "../../components/Font";
import { Loading } from "../../components/Loading";
import { usePageContent } from "../../hooks/useTranslations";
import { commonStyles } from "../../styles/components";
import { getTranslations } from "./translations";

function Privacy() {
  const translations: any = usePageContent({ page: "Landing Page" });
  const content = getTranslations(translations);
  const {
    baseContainer,
    headingBoxStyle,
    headingTextStyle,
    bodyTextStyle,
    pageContentBox,
  } = commonStyles;

  const state = useLocation();
  const { policyText, policyHeading } = state.state;

  if (!policyText && !policyHeading) {
    return <Loading />;
  }

  return (
    <Container styles={baseContainer}>
      <Container styles={headingBoxStyle}>
        <Font styles={headingTextStyle}>{policyHeading}</Font>
      </Container>
      <Container styles={pageContentBox}>
        <Font styles={bodyTextStyle}>{policyText}</Font>
      </Container>
      <div style={{ height: "600px" }}></div>
    </Container>
  );
}

export default Privacy;
