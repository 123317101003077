import React, { useState, useEffect } from "react";
import { useContentfulContext } from "./Api/context";
import { fetchContentful } from "./Api/fetchContentful";
import { Container } from "./components/Container";
import Footer from "./components/Footer";
import Header from "./components/Header";
import PageRoutes from "./routes";
import { pageStyles } from "./styles";

function App() {
  const { appStyles } = pageStyles;
  const context = useContentfulContext();
  const getContent = () => {
    fetchContentful().then((response) => {
      context.updatePageTexts(response.pageTexts);
      context.updateAssets(response.assets);
      context.updateSponsors(response.sponsors);
      context.updateNews(response.news);
      context.updateGalary(response.galary);
      context.updatePlayers(response.players);
      context.updateBoardMembers(response.boardMembers);
      context.updateDocuments(response.documents);
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <Container styles={appStyles.baseStyles}>
      <Header />
      <PageRoutes />
      <Footer />
    </Container>
  );
}

export default App;
