import { FunctionComponent } from "react";
import { css } from "@styled-system/css";
import styled from "styled-components";
import { ReactNode } from "react";
import { SystemStyleObject } from "@styled-system/css";

type T_RootElementProps = {
  children: ReactNode;
  styles: SystemStyleObject;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
};
export const Container: FunctionComponent<T_RootElementProps> = styled.div<T_RootElementProps>`
  ${(props: T_RootElementProps) => css(props.styles)};
`;
