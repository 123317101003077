import { SystemStyleObject } from "@styled-system/css";
import { globalStyles } from "../global";

export function newsImageContainer(props: any): SystemStyleObject {
  return {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  };
}
export function newsImage(props: any): SystemStyleObject {
  return {
    width: ["200px", "300px"],
    height: "auto",
    marginBottom: "30px",
  };
}
