import React, { useState } from "react";
import { componentsStyles } from "../styles";
import { Container } from "./Container";
import { Image } from "./Image";
import logo from "../Assets/logo.svg";
import { Font } from "./Font";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [navItem, setNavItem] = useState("")
  const { navItems, navItemsActive,baseStyles, headerContentContainerStyles,logoWrapper, navigationContainer } = componentsStyles.headerStyles;

  const handleNavItemClick = (item:string) => {
    navigate(`/${item}`)
    setNavItem(item)
  }

  return (
    <Container styles={baseStyles}>
      <Container styles={headerContentContainerStyles}>
      <Image
        loading="lazy"
        onClick={() => { navigate("/"); setNavItem("") }}
        src={logo}
        alt="logo"
        styles={logoWrapper}
      />
      <Container styles={navigationContainer}>
        <Font onClick={()=>handleNavItemClick("about")} styles={navItem==="about"?navItemsActive:navItems}>
          About
        </Font>
        <Font
          onClick={()=>handleNavItemClick("galary")}
          styles={navItem==="galary"?navItemsActive:navItems}
        >
          Gallery
        </Font>
        <Font
          onClick={()=>handleNavItemClick("players")}
          styles={navItem==="players"?navItemsActive:navItems}
        >
          Players
        </Font>
      </Container>
      </Container>
    </Container>
  );
}

export default Header;
