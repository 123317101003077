export const getTranslations = (content: any) => {
  if (content) {
    return {
      galaryTitle: content.galaryTitle??"GALARY",

    };
  } else {
     return {
      galaryTitle:"GALARY",

    };
  }
};
