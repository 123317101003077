import React from "react";
import { BallTriangle } from "react-loader-spinner";
import { componentsStyles, pageStyles } from "../styles";
import { Container } from "./Container";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./heropic.module.css";
import { Font } from "./Font";

const { loaderStyles } = componentsStyles;

export const Loading = () => {
  return (
    <Container styles={loaderStyles.baseStyles}>
      <BallTriangle
        height={600}
        width={500}
        radius={8}
        color="white"
        ariaLabel="ball-triangle-loading"
        wrapperStyle={{ width: "300px", height: "300px", margin: "0 auto" }}
        visible={true}
      />
      <Font styles={loaderStyles.loadingText}>
        Content loading, please wait...
      </Font>
    </Container>
  );
};
