import { Routes, Route } from "react-router-dom";
import AboutPage from "./pages/AboutPage/About";
import Galary from "./pages/Galary/Galary";

import LandingPage from "./pages/LandingPage/LandingPage";
import News from "./pages/News/News";
import Players from "./pages/Players/Players";
import Privacy from "./pages/Privacy/Privacy";

const PageRoutes = () => (
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route path="/about" element={<AboutPage />} />
    <Route path="/galary" element={<Galary />} />
    <Route path="/players" element={<Players />} />
    <Route path="/privacy" element={<Privacy />} />
    <Route path="/news/:id" element={<News />} />
  </Routes>
);

export default PageRoutes;
