import { FunctionComponent, ReactNode } from "react";
import { css } from "@styled-system/css";
import styled from "styled-components";
import { SystemStyleObject } from "@styled-system/css";

type T_RootElementProps = {
  styles: SystemStyleObject;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: ReactNode;
  // onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
export const Font: FunctionComponent<T_RootElementProps> = styled.p<T_RootElementProps>`
  ${(props: T_RootElementProps) => css(props.styles)};
`;
