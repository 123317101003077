import React, {useState} from "react";
import { pageStyles } from "../styles";
import { Container } from "./Container";
import { Image } from "./Image";
import { useAssets } from "../hooks/useTranslations";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./heropic.module.css";

type T_Image = {
  title: string;
  description: string;
  url: string;
};

export const Heropic = () => {
  const [loadImage, setLoadImage] = useState(false)
  const { heroContainer, heroContainerEmpty, heroPic } = pageStyles.landingpageStyles;
  const assets = useAssets();
  const heroImages: T_Image[] | undefined = assets?.filter(
    (item) => item.title === "hero image"
  );

  setTimeout(() => {
    setLoadImage(true)
  }, 1000);

  if (!loadImage) {
    return (
      <Container styles={heroContainerEmpty}>
        Loading..
      </Container>
    )
  }

  return (
    <Container styles={heroContainer}>
      <Carousel
        dynamicHeight={false}
        infiniteLoop={true}
        showThumbs={false}
        autoPlay
      >
        {heroImages?.map((image, index) => (
          <div key={index}>
            <Image loading="lazy" styles={heroPic} alt="hero" src={image.url} />
            <p className={styles.legend}>{image.description}</p>
          </div>
        ))}
      </Carousel>
    </Container>
  );
};
