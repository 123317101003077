import React from "react";
import { pageStyles } from "../styles";
import { Container } from "./Container";
import { Image } from "./Image";
import { Font } from "./Font";
type T_SponsorsProps = {
  title: string;
  data: any;
};

export const Sponsors = (props: T_SponsorsProps) => {
  const {
    sponsorsContainer,
    mainSponsorImage,
    generalSponsorsImage,
    mainSponsorsContainer,
    sponsorsHeading,
  } = pageStyles.landingpageStyles;


  const mainSponsors = props.data.filter((sponsor: any) => sponsor.mainSponsor);
  const generalSponsors = props.data.filter(
    (sponsor: any) => !sponsor.mainSponsor
  );
  return (
    <Container styles={mainSponsorsContainer}>
      <Font styles={sponsorsHeading}>{props.title}</Font>
      <Container styles={sponsorsContainer}>
        {mainSponsors.map((sponsor: any) => {
          return (
            <Image
              loading="lazy"
              styles={mainSponsorImage}
              alt="sponsor"
              src={sponsor.image}
              key={sponsor.sponsorName}
            />
          );
        })}
      </Container>
      <Container styles={sponsorsContainer}>
        {generalSponsors.map((sponsor: any) => {
          return (
            <Image
              loading="lazy"
              styles={generalSponsorsImage}
              alt="sponsor"
              src={sponsor.image}
              key={sponsor.sponsorName}
            />
          );
        })}
      </Container>
    </Container>
  );
};
