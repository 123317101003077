import React from "react";
import { componentsStyles } from "../styles";
import { Container } from "./Container";
import { Font } from "./Font";
import { Image } from "./Image";
import email from "../Assets/email.png";
import phone from "../Assets/phone.png";
import facebook from "../Assets/facebook.png";
import insta from "../Assets/insta.png";
import { useNavigate } from "react-router";
import { usePageContent } from "../hooks/useTranslations";

function Footer() {
  const navigate = useNavigate();
  const translations: any = usePageContent({ page: "Footer" });

  if (!translations) {
    return <p>LOADING...</p>;
  }

  const { footerStyles } = componentsStyles;
  return (
    <Container styles={footerStyles.baseStyles}>
      <Container styles={footerStyles.footerContentContainer}>
      <Container styles={footerStyles.leftBox}>
        <Font styles={footerStyles.footerHeading}>
          {translations.footerTitle}
        </Font>
        <Container styles={footerStyles.footerInfoContainer}>
          <Image loading="lazy" styles={{ width: "20px" }} alt="email icon" src={email} />
          <Font styles={footerStyles.footerText}>{translations.email}</Font>
        </Container>
        <Container styles={footerStyles.footerInfoContainer}>
          <Image loading="lazy" styles={{ width: "20px" }} alt="phone icon" src={phone} />
          <Font styles={footerStyles.footerText}>{translations.phone}</Font>
        </Container>
        <Container styles={footerStyles.footerInfoContainer}>
          <Image loading="lazy"
            styles={{ width: "25px" }}
            alt="facebook icon"
            src={facebook}
          />
          <a style={{textDecoration:"none"}} target="_blank" rel="noreferrer" href={translations.facebookLink}>
            <Font styles={footerStyles.footerText({ type: "link" })}>
              {translations.facebookText}
            </Font>
          </a>
        </Container>
        <Container styles={footerStyles.footerInfoContainer}>
          <Image loading="lazy" styles={{ width: "25px" }} alt="insta icon" src={insta} />
          <a style={{textDecoration:"none"}} target="_blank" rel="noreferrer" href={translations.instaLink}>
            <Font styles={footerStyles.footerText({ type: "link" })}>
              {translations.instaText}
            </Font>
          </a>
        </Container>
      </Container>
      <Container styles={footerStyles.rightBox}>
        <Font styles={footerStyles.businessIdText}>
          {" "}
          <strong>{translations.businessIdKey}</strong>{" "}
          {translations.businessIdValue}
        </Font>
        <Font
          onClick={() =>
            navigate("/privacy", {
              state: {
                policyHeading: translations.privacyPolicyKey,
                policyText: translations.privacyPolicyText,
              },
            })
          }
          styles={footerStyles.privacyPolicyText}
        >
          {translations.privacyPolicyKey}
        </Font>
        <Font styles={footerStyles.courtesyText}>
          {translations.courtesyText}
        </Font>
      </Container>
      </Container>
    </Container>
  );
}

export default Footer;
