import { SystemStyleObject } from "@styled-system/css";
import { globalStyles } from "../global";

export function baseStyles(props: any): SystemStyleObject {
  return {
    width: "100%",
    height: "100vh",
  };
}

export function loadingText(props: any): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    color: "white",
    fontSize: ["22px", "32px"],
    margin: "300px 0 0 0 ",
    textAlign: "center",
  };
}
