import { SystemStyleObject } from "@styled-system/css";
import { globalStyles } from "../global";

export function playersBlockContainer(props: any): SystemStyleObject {
  return {
    width: "100%",
    backgroundColor: "white",
    boxShadow: "1px 3px 5px black",
    borderRadius: ["none", "4px"],
    padding: "20px",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  };
}
export function playersListContainer(props: any): SystemStyleObject {
  return {
    width: "100%",
    marginTop:"30px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  };
}

export function playerAndPhotoWrapper(props: any): SystemStyleObject {
  return {
    margin: "15px",
    ":hover": {
      transform: "scale(1.1)",
      transition: "ease-in-out 0.4s",
    },
  };
}

export function playerInfoWrapper(props: any): SystemStyleObject {
  return {
    width: "100%",
    padding: "10px",
    backgroundColor: "#063A5B",
    borderRadius: "0 0 4px 4px",
  };
}

export function playerPhoto(props: any): SystemStyleObject {
  return {
    width: ["320px", "400px"],
    height: ["350px", "400px"],
    objectFit: "cover",
    borderRadius: "4px 4px 0 0",
  };
}
export function playerName(props: any): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    fontWeight: "bold",
    color: "white",
    fontSize: ["18px", "22px"],
    marginBottom: "8px",
  };
}
export function playerInfo(props: any): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    color: "white",
    fontSize: ["14px", "18px"],
  };
}
