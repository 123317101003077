import React from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { Container } from "../../components/Container";
import { usePageContent } from "../../hooks/useTranslations";
import { getTranslations } from "./translations";
import { Font } from "../../components/Font";
import { commonStyles } from "../../styles/components";
import { useContentfulContext } from "../../Api/context";
import { pageStyles } from "../../styles";
import { Image } from "../../components/Image";
import { Loading } from "../../components/Loading";

function AboutPage() {
  const context = useContentfulContext();
  const { boardMembers, documents } = context;
  const translations: any = usePageContent({ page: "About Page" });
  const textContent= getTranslations(getTranslations(translations))

  const {
    baseContainer,
    headingBoxStyle,
    headingTextStyle,
    bodyTextStyle,
    pageContentBox,
    link
  } = commonStyles;
  const {
    boardMemberContainer,
    boardMemberInfoContainer,
    boardMemberInfoText,
    boardMemberName,
    boardMemberPhoto,
    boardMembersContainer,
    documentContainer,
  } = pageStyles.aboutPageStyles;

  if (!translations) {
    return <Loading />;
  }

  const bodyTexts = textContent.aboutPageTexts.split("##");

  return (
    <Container styles={baseContainer}>
      <Container styles={headingBoxStyle}>
        <Font styles={headingTextStyle}>{textContent.aboutUsTitle}</Font>
      </Container>
      <Container styles={pageContentBox}>
        <Font styles={bodyTextStyle}>
          <ReactMarkdown children={textContent.aboutPageTexts} remarkPlugins={[remarkGfm]} />
          </Font>

      </Container>
      <Container styles={pageContentBox}>
        <Font styles={headingTextStyle}>{textContent.membersTitle}</Font>
        <Font  styles={bodyTextStyle}>
          {textContent.membersIntro}
        </Font>
        <a target="_blank" rel="noreferrer" href={textContent.memberLink}>
          <Font  styles={link}>
            {textContent.membersText}
          </Font>
        </a>
      </Container>
      <Container styles={headingBoxStyle}>
        <Font styles={headingTextStyle}>{textContent.boardTitle}</Font>
        <Container styles={boardMembersContainer}>
          {boardMembers.map((item: any, Key: any) => (
            <Container key={item.name} styles={boardMemberContainer}>
              <Image
                loading="lazy"
                styles={boardMemberPhoto}
                alt="board-member"
                src={item.photo}
              />
              <Container styles={boardMemberInfoContainer}>
                <Font styles={boardMemberName}>{item.name}</Font>
                <Font styles={boardMemberInfoText}>{item.position}</Font>
                <Font styles={boardMemberInfoText}>{`${item.phone}`}</Font>
              </Container>
            </Container>
          ))}
        </Container>
      </Container>
      <Container styles={headingBoxStyle}>
        <Font styles={headingTextStyle}>{textContent.documentsTitle}</Font>
        <Container styles={documentContainer}>
          {documents.map((item: any, key: any) => (
            <li key={item.title}>
              <a style={{ fontSize: "20px" }} href={item.file}>
                {item.title}
              </a>
            </li>
          ))}
        </Container>
      </Container>
    </Container>
  );
}

export default AboutPage;
