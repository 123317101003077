import { SystemStyleObject } from "@styled-system/css";
import { globalStyles } from "../global";

export function baseContainer(props: any): SystemStyleObject {
  return {
    width: "100%",
  };
}
export function headingBoxStyle(props: any): SystemStyleObject {
  return {
    width: ["100%", "50%"],
    backgroundColor: "white",
    boxShadow: "1px 3px 5px black",
    borderRadius: ["none", "2px"],
    padding: "5px 10px",
    margin: "0 auto",
  };
}
export function itemContainer(props: any): SystemStyleObject {
  return {
    margin: "20px 0",
  };
}
export function galaryTitle(props: any): SystemStyleObject {
  return {
    width: ["95%", "85%"],
    margin: "0 auto",
    fontFamily: globalStyles.primaryFont,
    fontWeight: "bold",
    color: "white",
    fontSize: ["18px", "22px"],
    lineHeight: "1.5",
    borderBottom: "1px solid white",
    padding: "7px",
  };
}

export function imagesContainer(props: any): SystemStyleObject {
  return {
    width: ["95%", "85%"],
    display: "flex",
    margin: ["10px auto 50px auto", "10px auto 80px auto"],
    overflowX: "auto",
  };
}

export function imagesContainerLazy(props: any): SystemStyleObject {
  return {
    width: ["95%", "85%"],
    display: "flex",
    margin: ["10px auto 50px auto", "10px auto 80px auto"],
    overflowX: "auto",
    height: "250px", 
    backgroundColor: "RGBA(123,143,190,0.38)",
    justifyContent: "center",
    alignItems:"center"
  };
}

export function image(props: any): SystemStyleObject {
  return {
    width: ["300px", "550px"],
    height: ["200px", "400px"],
    objectFit: "cover",
    margin: "0 5px",
    boxSizing: "border-box",
  };
}
