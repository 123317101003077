export const getTranslations = (content: any) => {
  if (content) {
    return {
      newsUpdateTitle: content.newsUpdate??"News Update",
      ourPartnersTitle: content.ourPartners ?? "Our Partners",
    };
  } else {
    
    return {
      newsUpdateTitle: "News Update",
      ourPartnersTitle: "Our Partners",
    }
  }

};
