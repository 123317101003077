import { SystemStyleObject } from "@styled-system/css";
import { globalStyles } from "../global";

type T_FooterText = {
  type: string;
};

export function baseStyles(props: any): SystemStyleObject {
  return {
    width: "100%",
   
    backgroundColor: "black",
  };
}
export function footerContentContainer(props: any): SystemStyleObject {
  return {
    width: ["100%", "900px"],
    margin: "0 auto",
    padding:["0 20px", 0],
    display: "flex",
    flexDirection: ["column", "row"],
    backgroundColor: "black",
    justifyContent: "space-between",
  };
}


export function rightBox(props: any): SystemStyleObject {
  return {
    paddingTop: ["20px", "50px"],
  };
}
export function leftBox(props: any): SystemStyleObject {
  return {};
}

export function footerHeading(props: any): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    fontWeight: "bold",
    color: "white",
    fontSize: "26px",
    margin: "15px 0",
  };
}
export function footerText(props: T_FooterText): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    color: "white",
    fontSize: ["16px", "18px"],
    marginLeft: "8px",
    cursor: props.type === "link" ? "pointer" : "none",
    textDecoration:"none"
  };
}



export function footerInfoContainer(props: any): SystemStyleObject {
  return {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  };
}

export function businessIdText(props: any): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    fontWeight: "bold",
    color: "white",
    fontSize: "18px",
    margin: "15px 0",
  };
}
export function courtesyText(props: any): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    fontWeight: "bold",
    color: "white",
    fontSize: "18px",
    margin: "15px 0",
  };
}

export function privacyPolicyText(props: any): SystemStyleObject {
  return {
    fontFamily: globalStyles.primaryFont,
    fontWeight: "bold",
    color: "white",
    fontSize: "18px",
    margin: "15px 0",
    cursor: "pointer",
  };
}
