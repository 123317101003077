import { useState } from "react";
import { useContentfulContext } from "../Api/context";
import { T_PageTexts } from "../types.general";

type T_UsePageContextProps = {
  page: string;
};

export const usePageContent = (props: T_UsePageContextProps) => {
  const context = useContentfulContext();
  let newData;
  if (context.pageTexts) {
    const pageData = context.pageTexts.filter(
      (item: T_PageTexts) => item.page === props.page
    );
    newData = pageData.reduce(
      (obj, item) => ({ ...obj, [item.slug]: item.textValue }),
      {}
    );
  }
  return newData;
};

export const useAssets = () => {
  const context = useContentfulContext();
  let newData;
  if (context.assets) {
    newData = Object.keys(context.assets).map((key) => {
      return context.assets[key];
    });
  }

  return newData;
};

export const useNews = () => {
  const context = useContentfulContext();
  let newData;
  if (context.news) {
    newData = Object.keys(context.news).map((key) => {
      return context.news[key];
    });
  }

  return newData;
};
