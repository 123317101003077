import { SystemStyleObject } from "@styled-system/css";
import { globalStyles } from "../global";

export function baseStyles(props: any): SystemStyleObject {
  return {
    width: "100%",
    boxShadow: "3px 30px 40px black",
    marginBottom: "10px",
    backgroundColor:"white"
  };
}
export function headerContentContainerStyles(props: any): SystemStyleObject {
  return {
    width: ["100%","900px"],
    margin: "0 auto",
    padding:["20px 15px", "30px 0"],
    display: "flex",
    justifyContent: "space-between",
    alignItems:"flex-end"
  };
}

export function logoWrapper(props: any): SystemStyleObject {
  return {
    width: ["50px", "80px"],
    height: ["50px", "80px"],
    cursor: "pointer",
  };
}

export function navigationContainer(props: any): SystemStyleObject {
  return {
    width: ["80%","60%", "40%"],
    display: "flex",
    justifyContent: "space-around",
    // paddingRight: [0, "200px"],
  };
}

export function navItems(): SystemStyleObject {
  return {
    fontSize: ["16px", "22px"],
    fontWeight: "bold",
    color: "#0E2A47",
    cursor: "pointer",
    ":hover": {
      textDecoration: "underline",
      textUnderlineOffset: "8px",
    },
  };
}

export function navItemsActive(props: any): SystemStyleObject {
  return {
    ...navItems(),
    color:"#DE5833",
    textDecoration: "underline",
    textUnderlineOffset: "8px",
    transition:"ease-in-out 0.2s"
  };
}
